import React, { Component } from "react";
import "./gs-infinite-list.scss";

export default class GSInfiniteList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    this.scrollView = React.createRef();
    this.scrollContent = React.createRef();
  }
  componentDidMount() {
    this.viewScrolled = this.handleScroll.bind(this);
    if (this.scrollView?.current) {
      this.scrollView?.current?.addEventListener?.("scroll", this.viewScrolled);
    }
  }

  handleScroll() {
    if (
      this.scrollView?.current?.scrollTop >=
      this.scrollContent?.current?.clientHeight - this.scrollView?.current?.clientHeight - 20
    ) {
      const filter = this.props.filter;
      if (filter && this.props.loadMore !== undefined) {
        if (!this.state.loading) {
          if (filter.page && filter.page.number) {
            filter.page.number += 1;
          } else if (filter.page) {
            filter.page += 1;
          }
          const loader = this.props.loadMore(filter);
          this.setState({ loading: true });
          if (loader) {
            loader.then(() => this.setState({ loading: false }));
          } else {
            this.setState({ loading: false });
          }
        }
      }
    }
  }

  render() {
    return (
      <infinite-list id="scroller" ref={this.scrollView}>
        <div id="scroller-elements" ref={this.scrollContent}>{this.props.children}</div>
      </infinite-list>
    );
  }
}
