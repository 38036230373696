import React, { useState } from "react";
import "./gs-file-Select.scss";

import GSItemList from "./gs-item-list";
import GSFileSelectItem from "./gs-file-select-item";
import GSItemInfo from "./gs-item-info";
import {
  faExclamationCircle,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";

export default function GSFileSelect(props) {
  const [valid, setValid] = useState(true);
  const {
    id,
    title,
    description,
    multiple,
    accept,
    sourceList,
    removeSourceItem,
    setSelectedFiles,
    failedValidation,
    required,
    ...rest
  } = props;
  const [fileList, setFileList] = useState([]);
  const keyPress = e => {
    const key = e.key;
    if (key === "Enter") {
      elementClicked();
    }
  };
  const onChange = e => {
    const files = Array.from(e.target.files);
    setFileList(files);
    setValid(true);
    if (setSelectedFiles) {
      setSelectedFiles(files);
    }
  };
  const hasItems = () => {
    if (sourceList) {
      return sourceList.length > 0;
    }
    return fileList.length > 0;
  };
  const removeItem = item => {
    const newList = fileList.filter(file => file !== item);
    setFileList(newList);
    if (removeSourceItem) {
      removeSourceItem(item);
    }
  };
  const getFileList = () => {
    if (sourceList) {
      return sourceList;
    }
    return fileList;
  };

  const imageFailedValidation = warn => {
    if (valid) {
      setValid(false);
    }
    if (failedValidation) {
      failedValidation(warn);
    }
  };

  const inValid = () => {
    if (required) {
      return fileList.length === 0;
    }
    return required;
  };

  const elementClicked = e => {
    const input = document.getElementById(
      `file-input-select-target-${id ?? ""}`
    );
    if (input && input.click) {
      input.click();
    }
    e?.stopPropagation?.();
  };

  return (
    <gs-file-select
      tabIndex={0}
      onKeyDown={keyPress}
      class={`${valid ? "valid" : "invalid"}`}
    >
      <div
        className={`select-input-container ${required ? "required" : ""}`}
        onClick={elementClicked}
      >
        {!hasItems() ?  <div className="title">
          {title ? title : "Select a file or drag files here"}
        </div>:null }
       
        {hasItems() && (
          <GSItemList
            items={getFileList()}
            listItem={item => (
              <GSFileSelectItem
                item={item}
                removeItem={removeItem}
                failedValidation={imageFailedValidation}
                {...rest}
              ></GSFileSelectItem>
            )}
            type="grid"
          ></GSItemList>
        )}
        {description && <div className="description">{description}</div>}
        <input
          id={`file-input-select-target-${id ?? ""}`}
          type="file"
          accept={accept}
          multiple={multiple}
          onClick={e => {
            e?.stopPropagation?.();
          }}
          onChange={onChange}
        ></input>
      </div>
      {inValid() && (
        <GSItemInfo
          icon={faExclamationCircle}
          description="This file is required"
        ></GSItemInfo>
      )}
    </gs-file-select>
  );
}
