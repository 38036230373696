import React from "react";
import "./gs-search-page.scss";

import GSInfiniteList from "./gs-infinite-list";
import GSActionBar from "./gs-action-bar";
import GSItemList from "./gs-item-list";
import GSButton from "./gs-button";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import GSPageLayout from "./gs-page-layout";

export default function GSSearchPage(props) {
  const hasFilterButtons =
    props.filterButtons && props.filterButtons.length > 0;
  return (
    <gs-search-page>
      <GSPageLayout
        title={props.title}
        toggleMenu={props.toggleMenu}
        pageActions={props.pageActions}
      >
        <page-content class={props.sidePanelClass}>
          {props.defaultSearch && (
            <GSActionBar
              header={<div className="search">{props.defaultSearch}</div>}
              pageActions={props.searchActions}
            ></GSActionBar>
          )}
          <GSInfiniteList loadMore={props.loadMore} filter={props.filter}>
            {hasFilterButtons && (
              <div className="filter-buttons">
                <GSItemList
                  itemSelected={props.clearFilterItem}
                  type="horizontal medium-gap"
                  items={props.filterButtons}
                  listItem={button => (
                    <GSButton
                      title={button.name}
                      type="black pill secondary"
                      rightIcon={faTimesCircle}
                    ></GSButton>
                  )}
                ></GSItemList>
              </div>
            )}
            {props.itemsList}
          </GSInfiniteList>
        </page-content>
      </GSPageLayout>
    </gs-search-page>
  );
}
