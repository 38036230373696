import React, { Component } from "react";
import "./gs-toggle.scss";
import { handleEnterKey } from "../helpers/Utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes } from "@fortawesome/free-solid-svg-icons";

export default class GStoggle extends Component {
  constructor(props) {
    super(props);
    this.state = { value: false };
  }
  keyPressed = e => {
    handleEnterKey(e, () => {this.props.onClick?.()})
  };
  render() {
    const {
      label,
      value,
      onClick,
      trueDescription,
      falseDescription,
      disabled,
      rowReverse
    } = this.props;
    return (
      <gs-toggle
        tabindex={0}
        onClick={disabled ? null : onClick}
        onKeyPress={e => {
          if(!disabled) {
            this.keyPressed(e);
          }
        }}
      >
        {label && <div className=" toggle-label">{label}</div>}
        <div className={`toggle-action ${disabled ? "disabled" : ""} ${rowReverse ? "rowReverse" : ""}` }>
          <div className={`toggle-container ${value}`}>
            <div className={`toggle-track ${value}`}>
              <div className="toggle-indicator"></div>
            </div>
          </div>
          <div className="toggle-detail">
            <div className="toggle-value">
              {value ? trueDescription : falseDescription}
            </div>
          </div>
        </div>
      </gs-toggle>
    );
  }
}
