import React from "react";
import "./gs-radio-button.scss";
import { handleEnterKey } from "../helpers/Utilities";

export default function GSRadioButton(props)
{
  const indicatorClass = props.selectedOption && props.selectedOption.value === props.value ? "button-indicator selected": "button-indicator";
  const keyPress = (e) => {
    handleEnterKey(e, (e) => {props?.onClick?.()})
  }
  return(
    <gs-radio-button onClick={props.onClick}>
      <div className="button" tabIndex={0} onKeyPress={keyPress}>
        <div className={indicatorClass}></div>
      </div>
      <div className="label">{props.label}</div>
    </gs-radio-button>
  )
}