import React from "react";
import "./gs-app-navigation-header.scss";

export default function GSAppNavigationHeader(props) {
  const { logo, title } = props;
  return (
    <gs-app-navigation-header>
      <div className="app-title">
        {logo && (
          <div className="title-logo">
            <img src={logo} alt="Logo"></img>
          </div>
        )}
        <div className="nav-header-title">{title}</div>
      </div>
    </gs-app-navigation-header>
  );
}
