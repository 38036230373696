import React from "react";
import "./gs-quick-filter.scss";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import GSButton from "./gs-button";
import GSItemList from "./gs-item-list";

const GSQuickFilter = props => {
  const {
    filteredList,
    getItem,
    itemSelected,
    itemRemoved,
    getDefaultSearch,
    selectedItem,
    selectedList,
    multiple,
    emptySearchList,
    emptySelectedList,
    loading,
    loadingMainText,
    loadingSubtext,
    disabled,
    searchListType,
    selectListType
  } = props;

  const getItemList = () => {
    if (disabled) {
      return;
    }
    return (
      <GSItemList
        items={filteredList}
        listItem={item => getItem?.(item)}
        itemSelected={item => itemSelected?.(item)}
        emptyMessage={emptySearchList}
        loading={loading}
        loadingMainText={loadingMainText}
        loadingSubtext={loadingSubtext}
        type={`vertical selectable ${searchListType}`}
      ></GSItemList>
    );
  };

  const Search = () => {
    if (disabled) {
      return <div className="none-selected">No item selected...</div>;
    }
    return getDefaultSearch?.();
  };

  const removeItem = item => {
    itemRemoved?.(item);
  };

  const itemIsSelected = () => {
    return !multiple && selectedItem;
  };

  const getForm = () => {
    return (
      <div className="filter-form">
        {multiple && getSelectedList()}
        {Search()}
        {getItemList()}
      </div>
    );
  };

  const getSelectedItem = item => {
    return (
      <div className="selected-item">
        {getItem?.(item)}
        {!disabled ? (
          <GSButton
            buttonIcon={faTimesCircle}
            onClick={() => removeItem(item)}
          ></GSButton>
        ) : null}
      </div>
    );
  };

  const getSelectedList = () => {
    if(selectedList === undefined){
      return null
    }
    return (
      <GSItemList
        items={selectedList}
        listItem={item => getSelectedItem(item)}
        emptyMessage={emptySelectedList}
        type={`vertical small-gap ${selectListType}`}
      />
    );
  };

  const getContent = () => {
    if (itemIsSelected()) {
      return getSelectedItem(selectedItem);
    } else {
      return getForm();
    }
  };

  return <gs-quick-filter>{getContent()}</gs-quick-filter>;
};

export default GSQuickFilter;
