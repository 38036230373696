import React from 'react'
import './gs-side-panel-navigation.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GSButton from "./gs-button";

export default function GSSidePanelNavigation(props){
  return(
    <gs-side-panel-navigation>
      <div className="left-button">
        {props.leftIcon && <GSButton buttonIcon={props.leftIcon} onClick={props.leftButtonClick}></GSButton>}
        
      </div>
      <div className="header-title">{props.title}</div>
      <div className="right-button">
        {props.rightIcon && <GSButton buttonIcon={props.rightIcon} onClick={props.rightButtonClick}></GSButton>}
        
      </div>
    </gs-side-panel-navigation>
  )
}