import React from "react";
import GSAppNavigationItem from "./gs-app-navigation-item";

import "./gs-app-navigation.scss";
import GSButton from "./gs-button";
import GSItemList from "./gs-item-list";

import GSPageBanner from './gs-page-banner';
import GSItemInfo from './gs-item-info';

import { faChevronLeft, faGlobeAmericas, faGolfBall } from "@fortawesome/free-solid-svg-icons";
export default function GSAppNavigation(props) {
  const {
    showEnv,
    environment,
    environmentDescription,
    environmentActions,
    environmentType,
    navItems,
    footerItems,
    itemSelected,
    title,
    history,
    headerClicked,
    activeRoute
  } = props;
  function backButtonClicked(item) {
    if (props.itemSelected) {
      props.itemSelected(item);
    }
  }
  const onHeaderClick = () => {
    if(headerClicked)
    {
      headerClicked()
    }
  }
  return (
    <gs-app-navigation>
      {showEnv ? <GSPageBanner type={environmentType}  title={<GSItemInfo header={environment} description={environmentDescription}  icon={faGlobeAmericas}></GSItemInfo>} bannerActions={environmentActions} ></GSPageBanner>: ""}
      <div className="nav-header" onClick={onHeaderClick}>
        <div className="nav-header-title">
          <div>{title}</div>
        </div>
      </div>
      {history && (
        <div className="nav-back">
          <GSItemList
            items={history}
            listItem={historyItem => (
              <GSButton
                buttonIcon={faChevronLeft}
                title={historyItem.label}
                size="secondary"
                onClick={() => backButtonClicked(historyItem)}
              ></GSButton>
            )}
            type="horizontal"
          ></GSItemList>
        </div>
      )}

      <div className="main">
        <GSItemList
          items={navItems}
          listItem={navItem => (
            <GSAppNavigationItem
              itemSelected={itemSelected}
              navItem={navItem}
              activeRoute={activeRoute}
            ></GSAppNavigationItem>
          )}
          type="vertical"
        ></GSItemList>
      </div>
      <div className="nav-footer">
        <GSItemList
          items={footerItems}
          listItem={navItem => (
            <GSAppNavigationItem
              itemSelected={itemSelected}
              navItem={navItem}
            ></GSAppNavigationItem>
          )}
          type="vertical"
        ></GSItemList>
      </div>
    </gs-app-navigation>
  );
}
