import React from "react";
import './gs-image.scss';

const GSImage = (props) => {
  const {alt, src, size, ratio, noImageView} = props
  const hasImage = () => {
    return src && !src.endsWith?.("missing.png")
  }
  return(
    <gs-image class={`${size} ${ratio}`}>
      {hasImage() ? 
      <img alt={alt? alt:""} src={src}></img>
      :
      <div className="no-image">{noImageView}</div>
    } 
    </gs-image>
  )
}

export default GSImage