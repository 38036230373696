import React, { useState, useEffect } from "react";
import "./gs-field.scss";

import GSInput from "../components/gs-input";
import { isLink } from "../helpers/RegexHelper";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamation, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export default function GSField(props) {
  const [showError, setShowError] = useState(false);
  const [warning, setWarning] = useState(false);
  const {
    value,
    validation,
    isEditable,
    hintText,
    failedValidation,
    customView,
    type,
    charCount,
    maxLength,
    align
  } = props;

  useEffect(() => {
    runValidation();
  });

  const runValidation = () => {
    let valid = true;
    let warn = false;
    if (
      !props.required &&
      (props.value === "" || props.value === undefined || props.value === null)
    ) {
      setWarning(false)
      return false;
    }
    if (valid) {
      valid = requiredCheck();
      warn = valid ? false : "This field is required";
    }
    if (valid) {
      valid = characterCheck();
      warn = valid ? false : "Invalid count";
    }
    if (valid) {
      valid =
        validation && validation.isValid
          ? validation.isValid(props.value)
          : true;
      warn = valid ? false : validation.invalidLabel;
    }
    if (warn) {
      failedValidation?.(warn);
    }
    setWarning(warn);
    return warn;
  };

  const characterCheck = () => {
    if (props.maxLength && props.charCount) {
      return props.maxLength - props.charCount >= 0;
    }
    return true;
  };

  const requiredCheck = () => {
    if (props.required) {
      return props.value !== "";
    }
    return true;
  };

  const valueClicked = e => {
    if (isLink(value) && !isEditable) {
      gotoLink(value);
    }
  };

  const gotoLink = url => {
    window.open(url);
  };

  const showInvalidLabel = () => {
    if (warning && showError) {
      return true;
    }
    return false;
  };

  const isCustomField = () => {
    return typeof props.value !== "string";
  };

  const inputTouched = () => {
    setShowError(true);
  };

  const isRequired = () => {
    return props.required && props.isEditable;
  };

  const getEditableField = () => {
    if (customView) {
      if (React.isValidElement(props.value)) {
        return React.cloneElement(props.value, { inputTouched });
      }
      return props.value;
    } else {
      return (
        <GSInput
          {...props}
          textValue={props.value}
          onBlur={inputTouched}
        ></GSInput>
      );
    }
  };

  const hasValueInfo = () => {
    return props.isEditable && (warning || hintText || charCount || maxLength)
  }

  return (
    <gs-field>
      <div className={`field ${type ?? "column"} ${(align ?? '')}`}>
        {(props?.label ?? "") !== "" ? (
          <div className="field-header">
            <div className="label">{props.label}</div>
            {isRequired() && <div className="required">*</div>}
          </div>
        ) : null}
        <div
          className={`field-value ${isLink(value) ? "link" : ""}  ${(align ?? '')} ${showInvalidLabel() ? "invalid-input" : ""}`}
          onClick={valueClicked}
        >
          {props.description && (
            <div className="description">{props.description}</div>
          )}
          {props.isEditable ? getEditableField() : props.value}
          {hasValueInfo() && (
            <div className="value-info">
              {showInvalidLabel() ? (
                <div className="label invalid"><span className="warning"><FontAwesomeIcon icon={faExclamationCircle}/> </span>{warning}</div>
              ) : (
                <div className="label">{hintText}</div>
              )}
              {props.charCount && (
                <div
                  className={`character-count ${
                    characterCheck() ? "valid" : "invalid"
                  }`}
                >
                  {props.charCount}
                  {props.maxLength ? `/${props.maxLength}` : ""}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </gs-field>
  );
}
