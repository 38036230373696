import React from "react";
import './gs-info-card.scss';

const GSInfoCard = (props) => {
  const {layout, size, selectable} = props
  return (
    <gs-info-card class={`${size} ${selectable? 'selectable' : ''}`}>
      <div className={`layout ${size}`}>
      {layout}
      </div>
    </gs-info-card>
  )
}

export default GSInfoCard