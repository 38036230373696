import "./gs-page-layout.scss";
import React from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import GSButton from "./gs-button";
import GSActionBar from "./gs-action-bar";

export default function GSPageLayout(props) {
  const { title, toggleMenu, closeMenu } = props;
  function toggleMenuAction() {
    if (toggleMenu) {
      toggleMenu();
    }
  }
  return (
    <gs-page-layout
      onClick={e => {
        closeMenu;
      }}
    >
      <div className="header">
        {toggleMenu && (
          <div className="menu-button">
            <GSButton
              buttonIcon={faBars}
              type="light-grey"
              onClick={toggleMenuAction}
            ></GSButton>
          </div>
        )}

        <GSActionBar
          pageActions={props.pageActions}
          header={<div className="title">{title}</div>}
        ></GSActionBar>
      </div>
      {props.children}
    </gs-page-layout>
  );
}
