import React, { Component } from "react";
import "./gs-input.scss";

import GSButton from "./gs-button";
import GSSelect from "./gs-select";

export default class GSinput extends Component {
  constructor(props) {
    super(props);
    this.state = { defaultTextValue: "" };
  }

  keyUp = e => {
    if (e.key === "Enter") {
      if (this.props.onSubmit) {
        this.props.onSubmit(e.target.value);
      } else {
        console.log("no submit action");
      }
    }
  };

  textChanged = e => {
    if (this.props?.onChange) {
      this.props.onChange?.(e);
    } else {
      this.setState({ defaultTextValue: e.target.value });
    }
  };

  handleWheel = e => {
    if (this.props.type === "number") {
      e?.target?.blur?.();
    }
    null;
  };

  getValue = () => {
    const {
      textValue,
      rightIcon,
      leftIcon,
      rightIconClick,
      leftIconClick,
      isEditable,
      failedValidation,
      hintText,
      ...rest
    } = this.props;
    if (this.props.type == "text-area") {
      return (
        <textarea
          {...rest}
          value={
            this.props.textValue
              ? this.props.textValue
              : this.state.defaultTextValue
          }
          onChange={this.textChanged}
        ></textarea>
      );
    }
    if (this.props.type == "select") {
      return <GSSelect {...this.props}></GSSelect>;
    }
    return (
      <input
        type={this.props.type}
        pattern={this.props.pattern}
        value={
          this.props.textValue
            ? this.props.textValue
            : this.state.defaultTextValue
        }
        placeholder={this.props.placeholder}
        onBlur={this.props.onBlur}
        onChange={this.textChanged}
        onKeyUp={this.keyUp}
        min={this.props.min || ""}
        max={this.props.max || ""}
        onWheel={this.handleWheel}
      />
    );
  };

  render() {
    const { leftIcon, rightIcon } = this.props;
    return (
      <gs-input class={this.props.type}>
        {leftIcon && (
          <div className="left-icon" onClick={this.props.leftIconClick}>
            <GSButton type="secondary" buttonIcon={leftIcon} />
          </div>
        )}
        {this.getValue()}
        {rightIcon && (
          <div className="right-icon" onClick={this.props.rightIconClick}>
            <GSButton type="secondary" buttonIcon={rightIcon} />
          </div>
        )}
      </gs-input>
    );
  }
}
