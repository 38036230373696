import React, {Component} from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import './gs-stripe-card-element.scss';

// The react app using this component must have stripe setup for use,
// as this must be used inside of Stripe's Elements component
export default class GSStripeCardElement extends Component {
  constructor(props) {
    super(props);
  }

  render(){
    return (
      <gs-stripe-card-element>
        <div className='border'>
          <CardElement />
        </div>
      </gs-stripe-card-element>
    );
  }
};
