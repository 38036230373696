import React from "react";
import GSButton from "./gs-button";
import GSItemList from "./gs-item-list";
import "./gs-page-navigation.scss";
import {
  faChevronLeft,
  faChevronRight
} from "@fortawesome/free-solid-svg-icons";

export default function GSPageNavigation(props) {
  const { pages, currentIndex } = props;
  function getCurrentPage() {
    if (currentIndex === 0) {
      return 1;
    }
    if (currentIndex) {
      return currentIndex + 1;
    }
    return 0;
  }
  function renderAction(action) {
    return <GSButton {...action}></GSButton>;
  }
  function nextPage() {
    const { nextPage, pages, currentIndex } = props;
    if (nextPage) {
      if (currentIndex < pages.length - 1) {
        nextPage();
      }
    } else {
      console.log("no next function is defined");
    }
  }
  function previousPage() {
    const { previousPage, currentIndex } = props;
    if (previousPage) {
      if (currentIndex > 0) {
        previousPage();
      }
    } else {
      console.log("no previous function is defined");
    }
  }
  return (
    <gs-page-navigation>
      <div className="paging-info">
        <GSButton buttonIcon={faChevronLeft} size="secondary" onClick={previousPage}></GSButton>
        <div className="paging">
          <div className="current-page">{getCurrentPage()}</div>
          <div className="of">of</div>
          <div className="total-pages">{pages.length}</div>
        </div>
        <GSButton buttonIcon={faChevronRight} size="secondary" onClick={nextPage}></GSButton>
      </div>

      {props.navigationActions && (
        <GSItemList
          type="horizontal"
          items={props.navigationActions}
          listItem={item => renderAction(item)}
        ></GSItemList>
      )}
    </gs-page-navigation>
  );
}
