import React from "react";
import GSActionBar from "./gs-action-bar";
import GSField from "./gs-field";
import "./gs-form-section.scss";
import GSItemList from "./gs-item-list";

export default function GSFormSection(props) {
  return (
    <gs-form-section>
      {props.title && (
        <GSActionBar
          header={props.title}
          pageActions={props.sectionActions}
        ></GSActionBar>
      )}
      {props.fields && (
        <GSItemList
          {...props}
          items={props.fields}
          listItem={item => <GSField {...item}></GSField>}
        ></GSItemList>
      )}
      {props.extras}
    </gs-form-section>
  );
}
