import React from "react";
import "./gs-loading-spinner-overlay.scss";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export default function GSLoadingSpinnerOverlay(props) {

  return (
    <gs-loading-spinner-overlay>
      <div className={`loading-section ${props.isLtr ? 'is-ltr' : ''}`}>
        <div className={`loading-spinner ${(props.spinnerSize ?? "large")}`}>
          <FontAwesomeIcon icon={faCircleNotch} spin></FontAwesomeIcon>
        </div>
        <div className="text-section">
          {props.mainText ? 
            <div className="main-loading-text">
                {props.mainText}
            </div> : ''}
          {props.subText ? 
            <div className="sub-loading-text">
              {props.subText}
            </div> : ''}
        </div>
      </div>
    </gs-loading-spinner-overlay>
  )
}