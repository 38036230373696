import React from 'react'
import './gs-circle-image.scss'

export default function GSCircleImage(props) {
  return (
    <gs-circle-image>
      <div className={`avatar ${props.size? props.size: "medium"}`}>
        <img alt="" src={props.url}></img>
      </div>
    </gs-circle-image>
  )
}