import React, { Component } from "react";
import "./gs-action-bar.scss";
import GSButton from "./gs-button";
import GSToggle from "./gs-toggle";
import GSItemList from "./gs-item-list";

export default class GSActionBar extends Component {
  renderAction(action) {
    if (action.actionType === "toggle") {
      const {
        label,
        onClick,
        value,
        trueDescription,
        falseDescription,
        type
      } = action.pageActionProps;

      return <GSToggle {...action?.pageActionProps} />;
    } else {
      return (
        <GSButton
          title={action.buttonTitle}
          {...action}
          buttonIcon={action.actionIcon}
          onClick={action.actionClick}
        ></GSButton>
      );
    }
  }

  render() {
    return (
      <gs-action-bar class={this.props.type ?? ""}>
        <div className="head">{this.props.header}</div>

        {this.props.pageActions?.length > 0 && (
          <div className="actions">
            <GSItemList
              type="horizontal medium-gap"
              items={this.props.pageActions}
              listItem={item => this.renderAction(item)}
            ></GSItemList>
          </div>
        )}
      </gs-action-bar>
    );
  }
}
