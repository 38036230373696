import React, { useState } from "react";
import "./gs-app-layout.scss";

import GSAppNavigation from "./gs-app-navigation";
import GSActionBar from "./gs-action-bar";
import GSItemInfo from "./gs-item-info";

import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
export default function GSAppLayout(props) {
  const {
    nav,
    routes,
    notification,
    navigationItemSelected,
    headerClicked
  } = props;
  const [isOpen, setIsOpen] = useState(false);
  const noRouter = () => {
    return (
      <div className="no-router">
        Could not find any routes for this app! Please add a router
      </div>
    );
  };
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const closeMenu = () => {
    setIsOpen(false);
  };
  const mobileHeaderActions = () => {
    return [
      {
        actionIcon: isOpen ? faTimes : faBars,
        type: "transparent white",
        actionClick: toggleMenu
      }
    ];
  };
  const navigate = item => {
    if (navigationItemSelected) {
      navigationItemSelected(item);
    }
    closeMenu();
  };
  const hasNotifications = () => {
    return notification;
  };
  const onHeaderClick = () => {
    if (headerClicked) {
      headerClicked();
    }
  };
  const getNavTitle = () => {
    return (
      <div className="title" onClick={onHeaderClick}>
        <GSItemInfo header={nav.title}></GSItemInfo>
      </div>
    );
  };
  return (
    <gs-app-layout>
      <div className={`app-navigation ${isOpen ? "open" : ""}`}>
        <GSAppNavigation
          {...nav}
          headerClicked={headerClicked}
          itemSelected={navigate}
        ></GSAppNavigation>
      </div>
      <div className="mobile-header">
        <GSActionBar
          header={getNavTitle()}
          pageActions={mobileHeaderActions()}
        ></GSActionBar>
      </div>

      {notification && <div className="notifications">{notification}</div>}

      <div className="app-content">{routes ? routes : noRouter()}</div>
    </gs-app-layout>
  );
}
