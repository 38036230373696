import React from "react";
import "./gs-form.scss";

import GSActionBar from "./gs-action-bar";
import GSItemList from "./gs-item-list";
import GSFormSection from "./gs-form-section";

export default function GSForm(props) {
  return (
    <gs-form>
      {props.formTitle && (
        <div className="gs-form-header">
          <GSActionBar
            header={props.formTitle}
            pageActions={props.formActions}
          ></GSActionBar>
        </div>
      )}

      <GSItemList
        items={props.formSections?.filter?.(fs => !fs.isHidden)}
        listItem={item => <GSFormSection {...item}></GSFormSection>}
        type="vertical"
      ></GSItemList>
    </gs-form>
  );
}
