import React from "react";
import "./gs-info-group.scss";

import GSItemList from "./gs-item-list";

const GSInfoGroup = props => {
  const { dataGroups, type, itemGap, listType } = props;

  const hasGroupSections = () => {
    return group?.sections?.length > 0
  }

  const getGroup = group => {
    const { title } = group;
    return (
      <div className="groups">
        {title && <div className="title">{title}</div>}
        {getSections(group)}
      </div>
    );
  };

  const getSections = group => {
    if(group?.sections?.length > 0)
    {
      return (
        <GSItemList
          type={`vertical medium-large-gap`}
          items={group.sections}
          listItem={section => getSection(section.sectionItems)}
        />
      );
    }
  };

  const getSection = sectionItems => {
    return (
      <GSItemList
        type={`vertical ${itemGap ?? ""} info-section`}
        items={sectionItems}
        listItem={sectionItem => (
          <div className={sectionItem.type}>{sectionItem.value}</div>
        )}
      />
    );
  };

  return (
    <gs-info-group class={type}>
      <GSItemList
        type={`horizontal x-large-gap ${listType}` }
        items={dataGroups}
        listItem={group => getGroup(group)}
      ></GSItemList>
    </gs-info-group>
  );
};

export default GSInfoGroup;
