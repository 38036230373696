import React, { Component } from "react";
import "./gs-radio-group.scss";

import GSRadioButton from "./gs-radio-button";

class GSRadioGroup extends Component {
  render() {
    return (
      <gs-radio-group class={this.props.isLtr ? 'is-ltr' : ''}>
        {this.props.options.map((option, index) => (
          <GSRadioButton key={index} label={option.label} value={option.value} selectedOption={this.props.selectedOption} onClick={() => {this.props.selectionChanged?.(option)}}></GSRadioButton>
        ))}
      </gs-radio-group>
    )
  }
}

export default GSRadioGroup