import React from "react";
import "./gs-empty-list.scss";

import GSButton from "./gs-button";
import GSItemList from "./gs-item-list";

const GSEmptyList = props => {
  const { title, detail, actions } = props;
  return (
    <gs-empty-list>
      <div className="messaging">
        <div className="empty-title">{title}</div>
        <div className="empty-detail">{detail}</div>
      </div>
      {actions?.length > 0 ? (
        <GSItemList
          type="horizontal medium-large-gap mobile-vertical action-buttons"
          items={actions}
          listItem={action => <GSButton {...action} />}
        ></GSItemList>
      ) : null}
    </gs-empty-list>
  );
};

export default GSEmptyList;
