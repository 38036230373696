import React, { Component } from 'react'
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async'
import './gs-select.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import GSButton from './gs-button';

export default class GSSelect extends Component {

  constructor(props) {
    super(props);
    
    this.selectType = (props.isAsync) ? AsyncSelect : Select;
    this.isMultiSelect = props.isMulti;
  }

  render() {
    const DropdownIndicator = ({ innerRef, innerProps}) => {
      return (
        <div className="select-dropdown-indicator" {...innerProps} ref={innerRef}>
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      );
    };
    
    const MultiValueLabel = props => {
      return (
        <components.MultiValueLabel {...props}>
          {props.data.label}
        </components.MultiValueLabel>
      );
    }

    const MultiValueRemove = props => {
      return (
        <components.MultiValueRemove {...props}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </components.MultiValueRemove>
      )
    }

    const ClearIndicator = ({ innerRef, innerProps}) => {
      return (
        <div className="select-clear-indicator" {...innerProps} ref={innerRef}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </div>
      );
    };

    const IndicatorSeparator = props => {
      return <span class="select-indicator-seperator" {...props} />;
    };
  
    const formatOptionLabel = ({ innerRef, innerProps, label, subLabel }) => (
      <div className="custom-select-option">
        <div className="main-select-label">{label}</div>
        {subLabel ?
          <div className="sub-select-label">
            {subLabel}
          </div>
          : ''}
      </div>
    );

    return (
      <gs-select>
        <this.selectType
          classNamePrefix="gs-select"
          {...this.props}
          value={this.props.selectedOption}
          noOptionsMessage={() => this.props.noOptionsMessage}
          formatOptionLabel={formatOptionLabel}
          styles={{
            singleValue: base => ({}),
          }}
          components={{ MultiValueLabel, MultiValueRemove, ClearIndicator, DropdownIndicator }} />
      </gs-select>
    )
  }  

}