import React, { Component } from 'react'
import './gs-button.scss'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class GSButton extends Component {
  enterKeyPressed = (e) => {
    const key = e.key;
      if (key === "Enter") {
        this.props?.onClick?.()
      }
  }
  render() {
    const { buttonIcon, rightIcon, title, type, onClick, isPill, size, isDisabled, isFocusable } = this.props;
    const buttonSize = (size === 'secondary' || isPill) ? 'secondary' : 'primary';
    const pillCss = isPill ? 'pill' : '';
    const disabled = isDisabled ? 'disabled' : 'enabled';
    
    return (
      <gs-button tabIndex={isFocusable ? isDisabled ? -1 : 0 : -1} onKeyDown={this.enterKeyPressed} onClick={isDisabled ? (e) => { e.stopPropagation(); return null; } : onClick} class={`${type} ${disabled} ${pillCss} ${buttonSize}`}>
        {buttonIcon &&
          <div className={`button-icon left`}>
            <FontAwesomeIcon icon={buttonIcon}></FontAwesomeIcon>
          </div>
        }
        {title &&
          <div className={`button-title`}>
            {title}
          </div>
        }
        {rightIcon &&
          <div className={`button-icon right`}>
            <FontAwesomeIcon icon={rightIcon}></FontAwesomeIcon>
          </div>
        }
      </gs-button>
    )
  }
}