import React from "react";
import "./gs-side-panel.scss";

export default function GSSidePanel(props){
  const stateClass = props.sidePanelOpen ? "open" : "closed"
  return (
    <gs-side-panel class={stateClass} onClick={(e)=> {e.stopPropagation()}}>
      {props.children}
    </gs-side-panel>
  )
}