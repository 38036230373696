import React from 'react'
import './gs-action-drawer.scss'

import GSButton from  './gs-button';
import GSItemList from './gs-item-list';

export default function GSActionDrawer(props)
{
  return(
   <gs-action-drawer>
     <GSItemList items={props.actions} listItem={(item) => (<GSButton {...item} title={item.name} type={item.type} onClick={item.action} isFocusable></GSButton>)} type="grid"></GSItemList>
   </gs-action-drawer>
  )
}